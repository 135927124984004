import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
// Third party
import { createTheme, StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import { CssBaseline } from '@mui/material/'
// Pages
import Landing from 'pages/Landing'

// Resources
import theme from 'res/theme'
import config from 'res/configs/config'
import PrivacyPolicy from 'pages/PrivacyPolicy/PrivacyPolicy'

const muiTheme = createTheme(theme)
const path = config.BASE_PATH_FRONTEND

// Render application
function App() {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={muiTheme}>
        <CssBaseline />
        <BrowserRouter basename={path}>
          <Routes>
            <Route path='/' element={<Landing />} />
            <Route path='/privacy' element={<PrivacyPolicy />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

export default App
