import React from 'react'
// Third party
import { Button } from '@mui/material'
import { ArrowBack } from '@mui/icons-material'

function GoBackButton(props) {
  const { onGoBack } = props

  const buttonStyle = {
    width: 'auto',
    marginRight: 'auto',
    marginTop: 2,
    marginBottom: 3,
    marginLeft: 2,
    padding: '8px 16px'
  }

  return (
    <Button size='small' variant='text' startIcon={<ArrowBack />} sx={buttonStyle} onClick={onGoBack}>
      Torna indietro
    </Button>
  )
}

export default GoBackButton
