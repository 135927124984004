import React from 'react'
import { useTheme } from '@mui/material/styles'
import { useNavigate, useLocation } from 'react-router-dom'

// Resources
import styles from './styles'

import GoBackButton from 'components/GoBackButton'

// Render component
function PrivacyPolicy() {
  const style = styles(useTheme())
  const location = useLocation()

  const navigate = useNavigate()
  const originPath = location.state.originPath

function handleGoBack() {
  navigate(originPath || -1)
}

  return (
    <div>
      <GoBackButton onGoBack={handleGoBack} />
      <div style={style.root}>
        <WebPrivacyPolicyAndCookie style={style} />
        <br />
        <br />
        <DataProcessingSections style={style} />
        <br />
        <br />
        <b>TIM S.p.A.</b>
        <br />
        <br />
        <br />
      </div>
    </div>
  )
}

/* Page sections */
function WebPrivacyPolicyAndCookie(props) {
  const { style } = props
  return (
    <>
      <span style={style.sectionTitle}>Web privacy policy & Cookie</span>
      <br />
      <span style={style.text}>
        La presente Privacy Policy ha lo scopo di descrivere le modalità di gestione di questo Sito, in riferimento all’uso dei cookie ed agli altri strumenti di tracciamento (di seguito tutti indicati per semplicità come “cookie”) ed al
        trattamento dei dati personali degli utenti/visitatori che lo consultano.
        <br />
        <br />
        Si tratta di un’informativa resa, ai sensi del Regolamento 2016/679/UE (Regolamento generale sulla protezione dei dati - di seguito GDPR), dell’articolo 122 del Codice in materia di protezione dei dati personali (D.Lgs. 196/03, il
        c.d. Codice privacy) e del Provvedimento generale del Garante Privacy dell’8 maggio 2014 in materia di cookie, come integrato dalle Linee Guida del Garante Privacy del 10 giugno 2021 in materia di cookie e altri strumenti di
        tracciamento, a coloro che si collegano al sito https://xr.tim.it.
        <br />
        <br />
        Questa Privacy Policy è resa soltanto per il sito sopra menzionato (di seguito “Sito” o “questo Sito”) e relativi possibili sottodomini, ma non anche per altri siti web eventualmente consultati dall’utente tramite appositi link.
        <br />
        <br />
        Il sito sopra indicato è di proprietà e gestione di TIM S.p.A.
        <br />
        <br />
        TIM S.p.A. garantisce il rispetto della normativa in materia di protezione dei dati personali, coerentemente con quanto prescritto nel Codice Etico del Gruppo Telecom Italia.
        <br />
        <br />
        Si invitano gli utenti/visitatori a leggere attentamente la presente Privacy Policy prima di inoltrare qualsiasi tipo di informazione personale e/o compilare qualunque modulo elettronico presente sul Sito nonché, in termini
        generali, di utilizzare il Sito stesso.
      </span>
    </>
  )
}

function DataProcessingSections(props) {
  const { style } = props
  return (
    <>
      <span style={style.sectionTitle}>Tipologia di dati trattati e finalità del trattamento</span>
      <ol>
        <li>
          <span style={style.subsectionTitle}>Dati di navigazione, informazioni relativo ad accesso ad aree autenticate e loro conservazione</span>
          <NavigationDataInfo style={style} />
        </li>
        <li>
          <span style={style.subsectionTitle}>Cookie</span>
          <Cookie style={style} />
        </li>
        <li>
          <span style={style.subsectionTitle}>Dati forniti volontariamente dagli utenti/visitatori</span>
          <ProvidedData style={style} />
        </li>
      </ol>
    </>
  )
}

function NavigationDataInfo(props) {
  const { style } = props
  return (
    <>
      <br />
      <span style={style.text}>
        I sistemi informatici e le procedure software preposte al funzionamento di questo Sito acquisiscono, nel normale esercizio, alcune informazioni di natura tecnica, tra cui: l’indirizzo IP; i nomi di domini dei computer utilizzati
        dagli utenti che si collegano al Sito; l’identificativo, la marca, il modello ed altri parametri relativi al sistema operativo del dispositivo; il codice indicante lo stato di risposta dati dal server; il tipo di browser;
        l’identificativo della singola sessione con indicazioni temporali di inizio e fine della sessione di navigazione; l’eventuale identificativo dell’account e delle attività svolte nel Sito, ecc..
        <br />
        <br />
        Queste informazioni vengono utilizzate per controllare il corretto funzionamento del Sito, per finalità di tipo statistico e, previo consenso per finalità di profilazione (qualora prevista). Le informazioni stesse vengono conservate
        per un periodo di tempo non superiore al conseguimento delle finalità per le quali sono raccolte o successivamente trattate, nonché per il periodo previsto per legge a fini amministrativi, di gestione di eventuali
        reclami/contenziosi, per eventuali accertamenti di reati informatici ai danni del Sito o procedimenti penali.
        <br />
        <br />
        Inoltre, in caso di accesso all’Area autenticata del Sito, TIM S.p.A. conserverà per le medesime finalità e tempistiche i dati personali dell’utente, inclusi quelli relativi ai log di tracciamento degli accessi e delle attività
        svolte nell’Area autenticata del Sito.
        <br />
        <br />
        Nessun dato derivante dal servizio web verrà diffuso.
        <br />
        <br />
      </span>
    </>
  )
}

function Cookie(props) {
  const { style } = props
  return (
    <>
      <br />
      <span style={style.text}>
        <ol>
          <li>
            <span style={style.textItalic}>Informazioni generali sui cookie</span>
            <br />
            <span style={style.text}>
              I cookie sono piccoli file di testo che i siti internet visitati dagli utenti inviano ai loro dispositivi (solitamente al browser o ad uno smartphone), dove vengono memorizzati per essere poi ritrasmessi agli stessi siti ad
              ogni successiva visita tramite il medesimo dispositivo. Inoltre, ogni sito può consentire la trasmissione dei c.d. cookie di “terze parti”, cioè quelli generati da siti internet diversi da quello che l’utente sta visitando
              (attraverso oggetti in esso presenti quali banner, immagini, mappe, suoni, specifici link a pagine web di altri domini).
              <br />
              <br />
              In funzione della loro durata, si distinguono in cookie di sessione (cioè quelli temporanei e cancellati automaticamente dal terminale al termine della sessione di navigazione, chiudendo il browser) ed in cookie persistenti
              (cioè quelli che restano memorizzati sul terminale fino alla loro scadenza o cancellazione da parte dell’utente).
              <br />
              <br />
              I cookie hanno diverse finalità. Sono in primo luogo utilizzati per la trasmissione della comunicazione o per fornire il servizio richiesto dall’utente; più precisamente permettono di abilitare ed ottimizzare il funzionamento
              del sito internet, eseguire autenticazioni informatiche e prevenire abusi, monitorare le sessioni, migliorare l’esperienza di navigazione degli utenti, ad esempio mantenendo attiva la connessione ad aree riservate durante la
              navigazione attraverso le pagine del sito senza la necessita di reinserire User-Id e password e memorizzando informazioni specifiche riguardanti gli utenti stessi (tra cui le preferenze, il tipo di browser e di computer
              usato). Possono anche utilizzarsi (in forma anonima, se di terze parti) per effettuare analisi aggregate sul funzionamento del sito, sul numero di utenti e su come visitano il sito.
              <br />
              <br />
              I suddetti cookie sono detti “tecnici” (per il loro utilizzo non è necessario il consenso dell’utente), in quanto senza di essi alcune delle citate operazioni non potrebbero essere compiute o sarebbero più complesse e/o meno
              sicure.
              <br />
              <br />
              Diversamente, se i cookie sono utilizzati per ulteriori finalità, tipicamente per (i) analisi del comportamento, eventualmente anche tramite il tracciamento dell’utente autenticato attraverso l’utilizzo di diversi dispositivi,
              ed invio di messaggi promo-pubblicitari personalizzati (c.d. cookie di “profilazione”) e/o (ii) ottenere statistiche in forma aggregata sul numero degli utenti e su come questi visitano il sito (c.d. “analytics cookie”), è
              necessario il consenso dell’utente (a meno che gli analytics cookie forniti da terze parti siano stati anonimizzati). Infatti, prima dell’invio di queste tipologie di cookie sul terminale, ai sensi della normativa vigente, al
              momento in cui si accede alla home page o ad altra pagina del sito, è immediatamente mostrato in primo piano un banner con una prima informativa sintetica sull’uso dei cookie e sulla richiesta del consenso, che l’utente può
              prestare (cliccando sul pulsante “accetta”) oppure rifiutare (cliccando ad esempio sulla “X” o sul pulsante “Continua senza accettare” posto in alto a destra nel banner). In alternativa, l’utente potrà cliccare sul pannello di
              gestione delle preferenze (detto anche CMP – Cookie-Consent Management Platform) per impostare specificamente i consensi in base ai cookie desiderati.
              <br />
              <br />
              In ogni caso, i cookie possono essere letti o modificati solo dal sito internet che li ha generati; non possono essere utilizzati per richiamare nessun dato dal terminale dell’utente e non possono trasmettere virus
              informatici. Alcune delle funzioni dei cookie possono essere svolte anche da altre tecnologie; pertanto, nel contesto della presente web privacy policy, con il termine “cookie” si vuol far riferimento ai cookie ed a tutte le
              tecnologie similari.
              <br />
              <br />
            </span>
          </li>
          <li>
            <span style={style.textItalic}>Utilizzo dei cookie di prima parte in questo Sito</span>
            <br />
            <span style={style.text}>
              Questo Sito può utilizzare cookie di sessione e cookie persistenti.
              <br />
              <br />
              Le tipologie di cookie di prima parte generati e trasmessi da questo Sito sono:
              <ul>
                <li>
                  cookie <b>“tecnici”</b>, utilizzati per:
                  <ul>
                    <li>l’autenticazione e la gestione di una sessione di navigazione (ad esempio, per identificare e validare l’utente per l’accesso all’Area Riservata);</li>
                    <li>finalità di sicurezza (es. per tener conto del numero di login falliti identificando possibili abusi nell’autenticazione e prevenendo frodi);</li>
                  </ul>
                </li>
              </ul>
            </span>
            <br />
          </li>
          <li>
            <span style={style.textItalic}>Cookie analytics forniti da terze parti</span>
            <br />
            <span style={style.text}>
              TIM S.p.A. non si avvale di servizi di analytics forniti da terze parti per raccogliere informazioni e produrre statistiche aggregate sul numero degli utenti e su questi come visitano il presente Sito.
            </span>
            <br />
            <br />
          </li>
          <li>
            <span style={style.textItalic}>Cookie di “terze parti”</span>
            <br />
            <span style={style.text}>Questo Sito non consente la trasmissione al dispositivo dell’utente di cookie di terze parti.</span>
            <br />
            <br />
          </li>
          <li>
            <span style={style.textItalic}>Criteri di codifica dei cookie e tempi di conservazione</span>
            <br />
            <span style={style.text}>I criteri di codifica semantica ed i tempi di conservazione previsti per ciascun dei cookie tecnici presenti nella sottostante tabella sono:</span>
            <br />
            <table style={style.table}>
              <tbody>
                <tr>
                  <th style={style.tableHeader}>Nome Cookie</th>
                  <th style={style.tableHeader}>Funzionalità Cookie</th>
                  <th style={style.tableHeader}>Durata</th>
                </tr>
                <tr>
                  <th style={style.tableCell}>ar.accessToken(LocalStorage)</th>
                  <th style={style.tableCell}>Token usato per l’autenticazione delle chiamate API/REST</th>
                  <th style={style.tableCell}>persistenti</th>
                </tr>
                <tr>
                  <th style={style.tableCell}>ar.refreshToken(LocalStorage)</th>
                  <th style={style.tableCell}>Token usato per richiedere il refresh del token di accesso</th>
                  <th style={style.tableCell}>persistenti</th>
                </tr>
                <tr>
                  <th style={style.tableCell}>vr.accessToken(LocalStorage)</th>
                  <th style={style.tableCell}>Token usato per l’autenticazione delle chiamate API/REST</th>
                  <th style={style.tableCell}>persistenti</th>
                </tr>
                <tr>
                  <th style={style.tableCell}>vr.refreshToken(LocalStorage)</th>
                  <th style={style.tableCell}>Token usato per richiedere il refresh del token di accesso</th>
                  <th style={style.tableCell}>persistenti</th>
                </tr>
                <tr>
                  <th style={style.tableCell}>admin.accessToken(LocalStorage)</th>
                  <th style={style.tableCell}>Token usato per l’autenticazione delle chiamate API/REST</th>
                  <th style={style.tableCell}>persistenti</th>
                </tr>
                <tr>
                  <th style={style.tableCell}>admin.refreshToken(LocalStorage)</th>
                  <th style={style.tableCell}>Token usato per richiedere il refresh del token di accesso</th>
                  <th style={style.tableCell}>persistenti</th>
                </tr>
              </tbody>
            </table>
            <br />
            <br />
          </li>
          <li>
            <span style={style.textItalic}>Gestione delle scelte sui cookie</span>
            <br />
            <span style={style.text}>I cookie possono essere gestiti dall’utente attraverso il proprio browser.</span>
            <br />
            <span style={style.text}>
              <u>Impostazioni del browser</u>
            </span>
            <br />
            <span style={style.text}>
              L’utente/visitatore può gestire le proprie preferenze relative ai cookie attraverso le funzionalità presenti nei comuni browser (Internet Explorer, Mozilla Firefox, Google Chrome, Apple Safari), che consentono di
              cancellare/rimuovere i cookie (tutti o alcuni) o di cambiare le impostazioni del browser stesso in modo bloccare l’invio dei cookie o di limitarlo a siti specifici (rispetto ad altri). E’ possibile conoscere le modalità con
              cui configurare le impostazione dei browser (di norma accessibili dalla barra “Strumenti”) andando sulle pagine web di “Supporto” dei fornitori dei suddetti browser (individuabili anche tramite un comune motore di ricerca).
              <br />
              <br />
              La disabilitazione dei cookie non preclude di per sé l'utilizzo dei servizi del Sito internet; se però vengono cancellati/bloccati tutti i cookie, inclusi quelli tecnici, alcune operazioni non potrebbero essere compiute o
              sarebbero più complesse e/o meno sicure, come ad esempio, per l’esecuzione di attività all’interno di Aree Riservate del Sito (i cookie consentono infatti di effettuare e mantenere l'identificazione dell’utente nell'ambito
              della sessione).
              <br />
              <br />
            </span>
            <br />
            <br />
          </li>
        </ol>
      </span>
    </>
  )
}

function ProvidedData(props) {
  const { style } = props
  return (
    <>
      <br />
      <span style={style.text}>
        Qualora gli utenti/visitatori, collegandosi a questo sito, inviino propri dati personali per accedere a determinati servizi, ovvero per effettuare richieste in posta elettronica o compilando web form, ciò comporta l’acquisizione da
        parte di TIM S.p.A. dell’indirizzo del mittente e/o di altri eventuali dati personali che verranno trattati esclusivamente per rispondere alla richiesta, ovvero per la fornitura del servizio.
        <br />
        <br />
        I dati personali forniti dagli utenti/visitatori verranno comunicati a terzi solo nel caso in cui la comunicazione sia necessaria per ottemperare alle richieste degli utenti/visitatori medesimi.
        <br />
        <br />
        <b>Modalità del trattamento</b>
        Il trattamento viene effettuato attraverso strumenti informatici e telematici e/o manualmente per il tempo strettamente necessario a conseguire gli scopi per i quali i dati sono stati raccolti e comunque, in conformità alle
        disposizioni normative vigenti in materia.
        <br />
        <br />
        <b>Facoltatività del conferimento dei dati</b>
        A parte quanto specificato per i dati di navigazione, gli utenti/visitatori sono liberi di fornire i propri dati personali. Il loro mancato conferimento può comportare unicamente l’impossibilità di ottenere quanto richiesto.
        <br />
        <br />
        <b>Titolare, Data Protection Officer e categorie di persone autorizzate al trattamento dei dati in TIM S.p.A.</b>
        Il Titolare del trattamento è TIM S.p.A., con sede in Via Gaetano Negri, 1 - 20123 Milano. TIM ha nominato un Data Protection Officer , contattabile presso TIM al seguente indirizzo: via Gaetano Negri, n. 1 - 20123 Milano; oppure
        inviando una e-mail a: dpo.clientibusiness.tim@telecomitalia.it. L’elenco aggiornato dei dati di contatto del Data Protection Officer è consultabile sul sito www.gruppotim.it, link Privacy.
        <br />
        <br />
        I dati degli utenti/visitatori di questo Sito saranno trattati dai dipendenti di TIM S.p.A. i quali sono stati nominati persone autorizzate al trattamento dei dati personali ed hanno ricevuto, al riguardo, adeguate istruzioni
        operative.
        <br />
        <br />
        <b>Diritti degli interessati</b>
        Gli utenti/visitatori hanno il diritto di accedere in ogni momento ai dati che li riguardano e di esercitare, per quanto applicabili, gli altri diritti previsti dagli articoli da 15 al 22 del GDPR (es. chiedere l’origine dei dati,
        la rettifica dei dati inesatti o incompleti, la limitazione del trattamento, la cancellazione o l’oblio, la portabilità dei dati, nonché opporsi al loro utilizzo per motivi legittimi), contattando gratuitamente il numero telefonico
        191. Infine, gli utenti/visitatori hanno il diritto di proporre un reclamo al Garante per la protezione dei dati personali.
        <br />
        <br />
        <span style={{ flexDirection: 'row' }}>
          Per ulteriori informazioni sulla privacy e per consultare il testo sempre aggiornato dell’informativa resa da TIM alla clientela <a href='https://www.gruppotim.it/it/footer/privacy.html'>clicca qui</a>.
        </span>
      </span>
    </>
  )
}

export default PrivacyPolicy
