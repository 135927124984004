import React from 'react'
// Third party
import { Container, Grid, IconButton, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
// Resources
import arImage from 'res/images/AR.png'
import vrImage from 'res/images/VR.png'
import config from 'res/configs/config'
import styles from './styles'
// Components
import BottomBar from 'components/BottomBar'

const urls = {
  AR: config.AR_PATH,
  VR: config.VR_PATH
}

function Landing() {
  const style = styles(useTheme())

  return (
    <Container disableGutters sx={style.root}>
      <div style={style.leftHalf} />
      <div style={style.rightHalf}>
        <Grid container sx={style.content}>
          <Grid item sx={{ marginBottom: 8 }}>
            <Typography color='primary' fontSize={50} fontWeight='light'>
              Cosa vuoi fare?
            </Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={() => window.location.assign(urls.AR)} sx={style.appBtn}>
              <img width={90} src={arImage} alt='arImage'></img>
              <Grid item sx={{ display: 'flex', flexDirection: 'column', textAlign: 'left', margin: '20px' }}>
                <Typography color='primary' fontSize={40}>
                  AR COMPOSER
                </Typography>
                <Typography color='primary' fontSize={15}>
                  Per gestire esperienze in realtà aumentata
                </Typography>
              </Grid>
            </IconButton>
            <IconButton onClick={() => window.location.assign(urls.VR)} sx={style.appBtn}>
              <img width={90} src={vrImage} alt='arImage'></img>
              <Grid item sx={{ display: 'flex', flexDirection: 'column', textAlign: 'left', margin: '20px' }}>
                <Typography color='primary' fontSize={40}>
                  VR MANAGER
                </Typography>
                <Typography color='primary' fontSize={15}>
                  Per gestire esperienze in realtà virtuale
                </Typography>
              </Grid>
            </IconButton>
          </Grid>
        </Grid>
      </div>
      <BottomBar />
    </Container>
  )
}

export default Landing
