// TIMsans Fonts
import TIMSansLighterFont from './fonts/TIMSans-Thin.otf'
import TIMSansLightFont from './fonts/TIMSans-Light.otf'
import TIMSansRegularFont from './fonts/TIMSans-Regular.otf'
import TIMSansMediumFont from './fonts/TIMSans-Medium.otf'
import TIMSansBoldFont from './fonts/TIMSans-Bold.otf'

const TIMSansLighter = {
  'font-family': 'TIMsans',
  'font-style': 'normal',
  'font-weight': '100',
  src: `url('${TIMSansLighterFont}') format('opentype')`
}

const TIMSansLight = {
  'font-family': 'TIMsans',
  'font-style': 'normal',
  'font-weight': '200',
  src: `url('${TIMSansLightFont}') format('opentype')`
}

const TIMSansRegular = {
  'font-family': 'TIMsans',
  'font-style': 'normal',
  'font-weight': 'normal',
  src: ` url('${TIMSansRegularFont}') format('opentype')`
}

const TIMSansMedium = {
  'font-family': 'TIMsans',
  'font-style': 'normal',
  'font-weight': '600',
  src: `url('${TIMSansMediumFont}') format('opentype')`
}

const TIMSansBold = {
  'font-family': 'TIMsans',
  'font-style': 'normal',
  'font-weight': 'bold',
  src: `url('${TIMSansBoldFont}') format('opentype')`
}

export const fontFamily = {
  primary: 'TIMsans, Arial, sans-serif',
  secondary: 'TIMsans, Arial, sans-serif',
  bold: TIMSansBold,
  light: TIMSansLight,
  lighter: TIMSansLighter,
  medium: TIMSansMedium,
  regular: TIMSansRegular
}
