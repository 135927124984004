function styles(theme) {
  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      margin: `${theme.mixins.toolbar.minHeight}px`,
      marginTop: `${theme.mixins.toolbar.minHeight * 2}px`,
      textAlign: 'justify',
      textJustify: 'inter-word'
    },
    sectionTitle: {
      display: 'flex',
      justifyContent: 'left',
      fontSize: '18px',
      fontWeight: 'bold'
    },
    subsectionTitle: {
      display: 'flex',
      justifyContent: 'left',
      fontSize: '16px',
      fontWeight: 'bold',
      fontStyle: 'italic'
    },
    text: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'left',
      fontSize: '14px'
    },
    textItalic: {
      display: 'flex',
      flexDirection: 'column',
      fontStyle: 'italic',
      justifyContent: 'left',
      fontSize: '14px'
    },
    table: {
      backgroundColor: '#f2f2f2',
      border: '1px solid',
      borderCollapse: 'collapse',
      display: 'absolute'
    },
    tableCell: {
      fontSize: '12px',
      border: '1px solid',
      fontWeight: 'normal'
    },
    tableHeader: {
      border: '1px solid',
      textAlign: 'center'
    }
  }
}

export default styles
