import { fontFamily } from './constants'
import * as palette from './palette'
import blueGrey from '@mui/material/colors/blueGrey'
import grey from '@mui/material/colors/grey'

/**
 * @type {import("@mui/material").ThemeOptions}
 */

const theme = {
  typography: {
    useNextVariants: true,
    fontFamily: [fontFamily.primary].join(','),
    fontSize: 15,
    fontWeightUltraLight: 200,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightSemibold: 600,
    fontWeightBold: 700,
    fontWeightHeavy: 800
  },
  palette: {
    primary: {
      main: palette.blueTim,
      darker: palette.darkerBlueTim
    },
    secondary: {
      main: palette.lochmara,
      darker: palette.darkerBlueTim
    },
    tertiary: {
      main: palette.texasRose
    },
    text: {
      main: '#333333',
      secondary: blueGrey['100']
    },
    grey: {
      light: blueGrey['100'],
      main: palette.bermudaGrey
    },
    white: {
      main: '#ffffff',
      dark: grey['50']
    },
    green: {
      main: palette.carribeanGreen
    },
    error: {
      main: palette.torchRed
    }
  },
  components: {
    MuiDivider: {
      styleOverrides: {
        root: {
          marginTop: '0.5rem',
          marginBottom: '1rem'
        }
      }
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover'
      }
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard'
      },
      styleOverrides: {
        root: {}
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: palette.blueTim
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: palette.blueTim
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        fontFamily: `TIMsans`,
        h3: {
          marginTop: '3rem'
        },
        h4: {
          marginTop: '2rem'
        },
        h5: {
          marginTop: '1rem'
        },
        h6: {
          fontFamily: 'TIMsans',
          fontWeight: 'normal'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '25px',
          textTransform: 'none',
          padding: '0.33rem 2rem'
        }
      }
    },
    MuiCssBaseline: {
      styleOverrides: [
        // To have multiple fonts, I need multiple @font-face. I merge all the fonts into a single string
        fontFamily.bold,
        fontFamily.light,
        fontFamily.lighter,
        fontFamily.medium,
        fontFamily.regular
      ]
        .map(
          (font) =>
            `@font-face {\n${Object.keys(font)
              .map((k) => `${k}: ${font[k]};`)
              .join('\n')}\n}`
        )
        .join('\n')
    },
    MuiTabs: {
      styleOverrides: {
        root: ({ ownerState, theme }) => {
          return {
            ...(ownerState.indicatorColor === 'error' && {
              '.MuiTabs-indicator': {
                backgroundColor: theme.palette.error.main
              }
            })
          }
        }
      }
    }
  },
  mixins: {}
}

export default theme
