import backgroundImage from 'res/images/leftSide.png'

function styles(theme) {
  return {
    root: {
      display: 'flex',
      flexDirection: 'row',
      margin: 0,
      overflow: 'hidden'
    },
    leftHalf: {
      background: `url(${backgroundImage})`,
      height: '100%',
      left: '0px',
      position: 'absolute',
      width: '50%',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover'
    },
    rightHalf: {
      display: 'flex',
      height: '100%',
      position: 'absolute',
      right: '0px',
      width: '50%',
      justifyContent: 'center'
    },
    appname: {
      color: theme.palette.primary.main,
      fontSize: '32px',
      fontWeight: 'light',
      // margin: '5px'
    },
    appBtn: {
      display: 'flex',
      flexDirection: 'row',
      boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px',
      borderRadius: 0,
      borderBottom: 'solid 1px lightgrey',
      padding: '1rem 2rem',
      width: '100%'
    },
    content: {
      alignContent: 'center',
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    },
    logo: {
      objectFit: 'contain',
      width: '25%'
    },
    typography: {
      color: theme.palette.primary.main,
      fontSize: '32px',
      fontWeight: 'lighter',
      margin: '5px',
      width: '400px'
    }
  }
}

export default styles
