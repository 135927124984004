import React from 'react'
// Third Party
import { Link, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useNavigate, useLocation } from 'react-router-dom'
// Resources
import styles from './styles'

function BottomBar() {
  const navigate = useNavigate()
  const location = useLocation()

  const style = styles(useTheme())
  return (
    <>
      <div style={style.customNav}>
        <div style={style.left}>
          <Link
            sx={Object.assign(style.barElement, { cursor: 'pointer' })}
            onClick={() =>
              navigate('/privacy', {
                state: {
                  originPath: location.pathname
                }
              })
            }>
            Privacy and Cookie
          </Link>
          <Link
            href='mailto:xrsupport@telecomitalia.it?subject=Segnalazione%20Piattaforma%20XR&body=Nome%20Cliente:%0AProblematica%20da%20segnalare:%0AData%2Fora%20problema%20riscontrato:%0AAllegati%20utili%20all%27analisi:%0A%0AAltre%20informazioni%20utili:%0A%0A'
            sx={Object.assign(style.barElement, style.cursor)}>
            Richiedi supporto
          </Link>
        </div>
        <div style={style.right}>
          <Typography sx={style.barElement}>{`©${new Date().getFullYear()} Telecom Italia`}</Typography>
          <Typography sx={style.barElement}>{`Partita IVA: 00488410010`}</Typography>
        </div>
      </div>
    </>
  )
}

export default BottomBar
