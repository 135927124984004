function styles(theme) {
  return {
    customNav: {
      position: 'fixed',
      bottom: 0,
      width: '100%',
      backgroundColor: '#01329b',
      padding: '0.2rem 0 0.2rem 0',
      display: 'flex',
      flexFlow: 'row',
      alignItems: 'center',
      zIndex: 1000
    },
    left: {
      paddingLeft: '2rem',
      display: 'flex',
      flexFlow: 'row'
    },
    right: {
      paddingRight: '2rem',
      display: 'flex',
      flexFlow: 'row',
      marginLeft: 'auto'
    },
    barElement: {
      padding: '0 1rem',
      fontFamily: theme.typography.fontFamily,
      fontSize: '12px',
      color: theme.palette.white.main
    },
    cursor: {
      cursor: 'pointer'
    }
  }
}

export default styles
